.letter-image {
    width: 200px;
    height: 200px;
    margin: auto;
    transform: scale(0.8);
    transform-origin: top;
}

.animated-mail {
    position: relative;
    height: 150px;
    width: 200px;
    -webkit-transition: .4s;
    -moz-transition: .4s;
    transition: .4s;

    .body {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 0 100px 200px;
        border-color: transparent transparent var(--color1) transparent;
        z-index: 2;
    }

    .top-fold {
        position: absolute;
        top: 50px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 100px 0 100px;
        -webkit-transform-origin: 50% 0%;
        -webkit-transition: transform .4s .4s, z-index .2s .4s;
        -moz-transform-origin: 50% 0%;
        -moz-transition: transform .4s .4s, z-index .2s .4s;
        transform-origin: 50% 0%;
        transition: transform .4s .4s, z-index .2s .4s;
        border-color: var(--color2) transparent transparent transparent;
        z-index: 2;
    }

    .back-fold {
        position: absolute;
        bottom: 0;
        width: 200px;
        height: 100px;
        background: var(--color2);
        z-index: 0;
    }

    .left-fold {
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 50px 0 50px 100px;
        border-color: transparent transparent transparent var(--color3);
        z-index: 2;
    }

    .letter {
        left: 20px;
        bottom: 0px;
        position: absolute;
        width: 160px;
        height: 60px;
        background: var(--background);
        box-shadow: 0px 0px 10px 0px var(--shadow);
        z-index: 1;
        overflow: hidden;
        -webkit-transition: .4s .2s;
        -moz-transition: .4s .2s;
        transition: .4s .2s;

        .letter-border {
            height: 10px;
            width: 100%;
            background: repeating-linear-gradient(-45deg,
                    var(--color4),
                    var(--color4) 8px,
                    transparent 8px,
                    transparent 18px);
        }

        .letter-title {
            margin-top: 10px;
            margin-left: 5px;
            height: 10px;
            width: 40%;
            background: var(--color4);
        }

        .letter-context {
            margin-top: 10px;
            margin-left: 5px;
            height: 10px;
            width: 20%;
            background: var(--color4);
        }

        .letter-stamp {
            margin-top: 30px;
            margin-left: 120px;
            border-radius: 100%;
            height: 30px;
            width: 30px;
            background: var(--color4);
            opacity: 0.3;
        }
    }
}

.shadow {
    position: absolute;
    top: 200px;
    left: 50%;
    width: 400px;
    height: 30px;
    transition: .4s;
    transform: translateX(-50%);
    -webkit-transition: .4s;
    -webkit-transform: translateX(-50%);
    -moz-transition: .4s;
    -moz-transform: translateX(-50%);

    border-radius: 100%;
    background: radial-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.0), rgba(0, 0, 0, 0.0));
}

.letter-image-hover {
    .animated-mail {
        transform: translateY(50px);
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
    }

    .animated-mail .top-fold {
        transition: transform .4s, z-index .2s;
        transform: rotateX(180deg);
        -webkit-transition: transform .4s, z-index .2s;
        -webkit-transform: rotateX(180deg);
        -moz-transition: transform .4s, z-index .2s;
        -moz-transform: rotateX(180deg);
        z-index: 0;
    }

    .animated-mail .letter {
        height: 180px;
    }

    .shadow {
        width: 250px;
    }
}

.success-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;

    .check-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #4CAF50;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
        }

        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }

        .icon-line {
            height: 5px;
            background-color: #4CAF50;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line-tip {
                top: 46px;
                left: 14px;
                width: 25px;
                transform: rotate(45deg);
            }

            &.line-long {
                top: 38px;
                right: 8px;
                width: 47px;
                transform: rotate(-45deg);
            }
        }

        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(76, 175, 80, .5);
        }

        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }

    .check-icon.animate {
        &::after {
            animation: rotate-circle 4.25s ease-in;
        }

        .icon-line {
            &.line-tip {
                animation: icon-line-tip 0.75s;
            }

            &.line-long {
                animation: icon-line-long 0.75s;
            }
        }
    }
}

.failure-checkmark {
    width: 80px;
    height: 115px;
    margin: 0 auto;

    .failure-icon {
        width: 80px;
        height: 80px;
        position: relative;
        border-radius: 50%;
        box-sizing: content-box;
        border: 4px solid #af4c4c;

        &::before {
            top: 3px;
            left: -2px;
            width: 30px;
            transform-origin: 100% 50%;
            border-radius: 100px 0 0 100px;
        }

        &::after {
            top: 0;
            left: 30px;
            width: 60px;
            transform-origin: 0 50%;
            border-radius: 0 100px 100px 0;
        }

        &::before, &::after {
            content: '';
            height: 100px;
            position: absolute;
            background: #FFFFFF;
            transform: rotate(-45deg);
        }

        .icon-line {
            height: 5px;
            background-color: #af4c4c;
            display: block;
            border-radius: 2px;
            position: absolute;
            z-index: 10;

            &.line-left {
                top: 38px;
                left: 10px;
                width: 60px;
                transform: rotate(45deg);
            }

            &.line-right {
                top: 38px;
                right: 10px;
                width: 60px;
                transform: rotate(-45deg);
            }
        }

        .icon-circle {
            top: -4px;
            left: -4px;
            z-index: 10;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            position: absolute;
            box-sizing: content-box;
            border: 4px solid rgba(175, 76, 76, 0.5);
        }

        .icon-fix {
            top: 8px;
            width: 5px;
            left: 26px;
            z-index: 1;
            height: 85px;
            position: absolute;
            transform: rotate(-45deg);
            background-color: #FFFFFF;
        }
    }

    .failure-icon.animate {
        .icon-line {
            &.line-left {
                animation: icon-cross-left 0.75s;
            }

            &.line-right {
                animation: icon-cross-right 0.75s;
            }
        }
    }
}

@keyframes rotate-circle {
    0% {
        transform: rotate(-45deg);
    }

    5% {
        transform: rotate(-45deg);
    }

    12% {
        transform: rotate(-405deg);
    }

    100% {
        transform: rotate(-405deg);
    }
}

@keyframes icon-line-tip {
    0% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    54% {
        width: 0;
        left: 1px;
        top: 19px;
    }

    70% {
        width: 50px;
        left: -8px;
        top: 37px;
    }

    84% {
        width: 17px;
        left: 21px;
        top: 48px;
    }

    100% {
        width: 25px;
        left: 14px;
        top: 45px;
    }
}

@keyframes icon-line-long {
    0% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    65% {
        width: 0;
        right: 46px;
        top: 54px;
    }

    84% {
        width: 55px;
        right: 0px;
        top: 35px;
    }

    100% {
        width: 47px;
        right: 8px;
        top: 38px;
    }
}

@keyframes icon-cross-left {
    0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: scale(0) rotate(45deg);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: scale(1) rotate(45deg);
	}

	55% {
		animation-timing-function: ease-in;
		transform: scale(0.7) rotate(45deg);
	}

	72% {
		animation-timing-function: ease-out;
		transform: scale(1) rotate(45deg);
	}

	81% {
		animation-timing-function: ease-in;
		transform: scale(0.84) rotate(45deg);
	}

	89% {
		animation-timing-function: ease-out;
		transform: scale(1) rotate(45deg);
	}

	95% {
		animation-timing-function: ease-in;
		transform: scale(0.95) rotate(45deg);
	}

	100% {
		animation-timing-function: ease-out;
		transform: scale(1) rotate(45deg);
	}
}

@keyframes icon-cross-right {
    0% {
		animation-timing-function: ease-in;
		opacity: 0;
		transform: scale(0) rotate(-45deg);
	}

	38% {
		animation-timing-function: ease-out;
		opacity: 1;
		transform: scale(1) rotate(-45deg);
	}

	55% {
		animation-timing-function: ease-in;
		transform: scale(0.7) rotate(-45deg);
	}

	72% {
		animation-timing-function: ease-out;
		transform: scale(1) rotate(-45deg);
	}

	81% {
		animation-timing-function: ease-in;
		transform: scale(0.84) rotate(-45deg);
	}

	89% {
		animation-timing-function: ease-out;
		transform: scale(1) rotate(-45deg);
	}

	95% {
		animation-timing-function: ease-in;
		transform: scale(0.95) rotate(-45deg);
	}

	100% {
		animation-timing-function: ease-out;
		transform: scale(1) rotate(-45deg);
	}
}